import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

function MenuCategoryBackground({ className, children, filename }) {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allFile(filter: {relativePath: {glob: "categories/*"}}) {
          edges {
            node {
              id
              childImageSharp {
                fluid(quality: 90, maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              relativePath
            }
          }
        }
      }
    `,
  );

  const image = data.allFile.edges.find((item) => item.node.relativePath === `categories/${filename}`);
  if (!image) {
    throw Error(`Not found category background image ${filename}`);
  }

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={image.node.childImageSharp.fluid}
    >
      {children}
    </BackgroundImage>
  );
}

export default MenuCategoryBackground;
