import { graphql } from 'gatsby';
import React from 'react';
import PageDefault from '../../components/pages/menu/index.page';
import Layout from '../../layouts';

export default function Page({ data }) {
  return <Layout className="bg-menu"><PageDefault data={data} /></Layout>;
}

export const query = graphql`
  query {
    categories: allCategory {
      nodes {
        category
        category_ru
        category_en
        slug
        className
        background
      }
    }
  }
`;
