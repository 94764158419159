import React from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import Helmet from 'react-helmet';
import * as GlobalCSS from '../../../styles.module.css';
import Button from '../../Button';
import * as CSS from './index.module.css';
import I18NField from '../../I18NField';
import MenuCategoryBackground from '../../backgrounds/MenuCategoryBackground';

function Right() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
      <path d="M0 9.10606L0.910026 10L6 5L0.910026 0L0 0.893939L4.17995 5L0 9.10606Z" fill="#F4F2EA" />
    </svg>
  );
}

function Page({ data }) {
  return (
    <div className={CSS.wrapper}>
      <FormattedMessage id="menuTitle">
        {(msg) => (
          <Helmet
            title={`${msg} / Hookah Base - Shisha Lounge & Cocktails Bar`}
          />
        )}
      </FormattedMessage>

      <div className={classnames(GlobalCSS.textLogo, CSS.title)}><FormattedMessage id="menuTitle" /></div>

      {data.categories.nodes.map((category) => (
        <div className={classnames(CSS.category, CSS[category.className])} key={category.slug}>
          <MenuCategoryBackground filename={category.background}>
            <Button to={`/menu/${category.slug}`} type="link">
              <span><I18NField obj={category} property="category" /></span>
              <Right />
            </Button>
          </MenuCategoryBackground>
        </div>
      ))}
    </div>
  );
}

export default Page;
